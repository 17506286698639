<!--
 * @Author: kevinzgai zhanggai_ok@126.com
 * @Date: 2023-11-27 10:41:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-11-15 01:29:50
 * @FilePath: \zyt-mobile-frontend\src\views\cinemaAssistant\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="page">
    <div style="max-height: 99vh;overflow-y:scroll;">
      <cinema-base :base="cinemaBase" v-if="selectCinema.CinemaCode" @change="showCinemaListModel"></cinema-base>
      <warn-information
        v-if="selectCinema.CinemaCode"
        :cinema-id="selectCinema.CinemaCode"
        :datas="warningInfo"
      ></warn-information>
      <public-service v-if="selectCinema.CinemaCode" :cinema-id="selectCinema.CinemaCode"></public-service>
      <private-service v-if="selectCinema.CinemaCode" :cinema-id="selectCinema.CinemaCode"></private-service>
      <cinema-portrait
        v-if="selectCinema.CinemaCode"
        :cinema-id="selectCinema.CinemaCode"
        :datas="kpiInfo"
      ></cinema-portrait>
      <cinema-analyze
        v-if="showCinemaCompare"
        :cinema-id="selectCinema.CinemaCode"
      ></cinema-analyze>
    </div>
    <cinema-list-model ref="cinemaListModel" @select="changeCinema" @close="closeModel"></cinema-list-model>
  </div>
</template>

<script>
import cinemaBase from './modules/index/cinemabase'
import warnInformation from '@views/cinemaAssistant/modules/index/WarnInformation'
import publicService from '@views/cinemaAssistant/modules/index/publicService'
import privateService from '@views/cinemaAssistant/modules/index/privateService'
import cinemaPortrait from '@views/cinemaAssistant/modules/index/cinemaPortrait'
import cinemaAnalyze from '@views/cinemaAssistant/modules/index/cinemaAnalyze'
import cinemaListModel from '@comp/cinemaListModel/index.vue'
import { Toast } from 'vant'
import { zjApi as api } from '@/api/index'

export default {
  name: 'index',
  components: {
    cinemaBase,
    warnInformation,
    publicService,
    privateService,
    cinemaPortrait,
    cinemaAnalyze,
    cinemaListModel,
  },
  data() {
    return {
      showcinema: false,
      cinemaBase: {},
      warningInfo: {},
      kpiInfo: {},
      cinemaList: [],
      showCinemaCompare: true,
      selectCinema: {
        CinemaCode: null,
      },
    }
  },
  mounted() {
    if (this.$store.state.selectedCinema) {
      console.log(this.$store.state.selectedCinema)
      this.selectCinema = this.$store.state.selectedCinema
      this.getCinemaInfo()

    } else {
      this.$refs.cinemaListModel.show()
    }


    // if (this.selectCinema?.CinemaCode) {
    //   this.getCinemaInfo(this.selectCinema)
    // } else {
    //   this.$refs.cinemaListModel.show()
    // }
  },
  methods: {
    showCinemaListModel() {
      this.$refs.cinemaListModel.show()
    },
    getCinemaInfo() {
      const cinema = this.selectCinema
      const mobile = this.$store.state.userInfo.mobile
      const ps = {
        UserCode: mobile,
        CmdIndex: '101002',
        cmdArgs: [cinema.CinemaCode],
      }

      api.getPs({ ...ps }).then((r) => {
        const { code, result, Message } = r
        if (code === 200) {
          const data = result
          this.cinemaBase = {
            name: data.baseInfo.CinemaName,
            code: cinema.CinemaCode,
            cinemaLine: data.baseInfo.CinemaChain,
            user: data.baseInfo.LegalName,
            numberOfCinemas: data.baseInfo.ScreenCount,
            numberOfSeats: data.baseInfo.SeatCount,
          }
          this.warningInfo = data.warningInfo
          this.kpiInfo = data.kpiInfo
        } else {
          Toast(Message)
        }
      })
    },
    changeCinema(cinema) {
      console.log({ cinema })

      this.$store.commit('changeSelectedCinema', cinema)
      this.selectCinema = cinema

      this.getCinemaInfo()
    },
    closeModel() {
      if (!this.selectCinema.CinemaCode) {
        Toast('请选择影院')
        this.$refs.cinemaListModel.show()
      } else {
        this.$refs.cinemaListModel.close()
      }
    },
    goPath(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style scoped lang="less">
#page {
  //border: 1px solid red;
  padding: 0.1rem;
  padding-top: 0.5rem;
  background-image: linear-gradient(to bottom,
  #1666eb 0%,
  #2cdce5 22.99%,
  #f7f7f7 23%);

  .selectCinema {
    color: #fff;
  }

  .navbar {
    display: flex;
    flex-direction: row;
    height: 0.8rem;
    align-items: center;
    color: #fff;

    div {
      &:first-child,
      &:nth-child(3) {
        flex: 1;
        text-align: center;
      }

      &:nth-child(2) {
        flex: 5;
        text-align: center;
      }

      p {
        margin: 0;
        padding: 0;
      }

      .selectCinema {
        color: #fff;
      }
    }
  }
}
</style>

<template>
  <div>
    <van-popup
      v-model="showModal"
      position="bottom"
      :style="{ maxHeight: '90%', minHeight: '60%' }"
      close-icon="cross"
      round
      @click-overlay="onClose"
    >
      <div class="cinema-popup">
        <div class="popup-header">
          <span class="title">选择影院</span>
          <van-icon name="cross" @click="onClose" />
        </div>

        <div class="search-section">
          <van-search
            v-model="query.name"
            placeholder="请输入影院名称"
            @search="onSearch"
            shape="round"
            background="transparent"
          >
            <template #right-icon>
              <van-button
                class="blue-btn"
                size="small"
                round
                icon="search"
                @click="onSearch"
              >
                查询
              </van-button>
            </template>
          </van-search>
        </div>

        <div class="cinema-list" v-if="cinemaList.length > 0">
          <div
            v-for="(cinema, index) in cinemaList"
            :key="index"
            class="cinema-item"
            @click="selectCinema(cinema)"
          >
            <div class="cinema-info">
              <div class="cinema-name">{{ cinema.CinemaName }}</div>
              <div class="cinema-address">{{ cinema.CinemaAddress }}</div>
            </div>
            <van-button
              class="blue-btn"
              size="small"
              round
            >
              选择
            </van-button>
          </div>
        </div>

        <div class="empty-state" v-else>
          <van-empty
            image-size="100"
            description="请输入正确影院名称进行搜索"
          >
            <template #image>
              <van-icon name="search" size="48" class="empty-icon" />
            </template>
          </van-empty>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'

export default {
  name: 'CinemaListModel',
  data() {
    return {
      showModal: false,
      query: {
        name: '',
      },
      cinemaList: [],
    }
  },
  methods: {
    show() {
      // Toast('请输入影院名称进行搜索')
      this.showModal = true
    },
    getUserManageList() {
      const mobile = this.$store.state.userInfo.mobile
      const ps = {
        'UserCode': `${mobile}`,
        'CmdIndex': '101001',
        'cmdArgs': [`${this.query.name || '浙江'}`],
      }

      zjApi.getPs({ ...ps }).then((r) => {
        const {code,result} = r
        if (code === 200) {
          this.cinemaList =result
        }
      })
    },
    selectCinema(cinema) {
      this.$emit('select', cinema)
      this.showModal = false
    },
    close() {
      this.showModal = false
    },
    onClose() {
      this.$emit('close')
    },
    onSearch() {
      this.getUserManageList()
    },
  },
}
</script>

<style scoped lang="less">
.cinema-popup {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popup-header {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }

  .van-icon {
    font-size: 20px;
    color: #999;
    padding: 4px;

    &:active {
      background: #f5f5f5;
      border-radius: 50%;
    }
  }
}

.search-section {
  padding: 12px 16px;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1;

  :deep(.van-search) {
    padding: 0;

    .van-search__content {
      background: #f5f7fa;
    }
  }

  .search-btn {
    margin-left: 12px;
  }
}

.cinema-list {
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
}

.cinema-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f5f5f5;
  transition: all 0.3s ease;

  &:active {
    background: #f9f9f9;
  }

  .cinema-info {
    flex: 1;
    margin-right: 16px;

    .cinema-name {
      font-size: 16px;
      font-weight: 500;
      color: #333;
      margin-bottom: 4px;
    }

    .cinema-address {
      font-size: 13px;
      color: #999;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.empty-state {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 0;

  .empty-icon {
    color: #c8c9cc;
    margin-bottom: 8px;
  }
}

:deep(.van-popup) {
  max-height: 90% !important;
}

.blue-btn {
  background: #1989fa;
  border: none;
  color: #fff;
  font-weight: 500;

  &:active {
    background: #0076e4;
  }

  .van-icon {
    color: #fff;
  }
}
</style>

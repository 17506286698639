<template>
  <div class="page">
    <div class="header">
      <div class="title">竟对影院分析</div>
    </div>
    <div class="warning-grid">
      <div
        class="number-item"
        @click="goPath(item.path, { cinemaId })"
        v-for="(item, index) in icons"
        :key="index"
      >
        <div class="icon-container">
          <van-image width="60" height="60" :src="item.icon" />
        </div>
        <div class="item-name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cinemaId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      icons: [
        {
          name: '票价分析',
          icon: require('@/assets/icon/ca-sf.svg'),
          path: '/cinemaAssistant/competitorCinema/boxOffice',
        },
        {
          name: '上座率分析',
          icon: require('@/assets/icon/ca-sx.svg'),
          path: '/cinemaAssistant/competitorCinema/attendance',
        },
        {
          name: '排片场次分析',
          icon: require('@/assets/icon/ca-sc.svg'),
          path: '/cinemaAssistant/competitorCinema/session',
        },
        {
          name: '排片效益分析',
          icon: require('@/assets/icon/ca-pp.svg'),
          path: '/cinemaAssistant/competitorCinema/benefit',
        },
      ],
    }
  },
  name: 'WarnInformation',
  methods: {
    goPath(path, query) {
      console.log({ path, query })
      this.$router.push({
        path,
        query: { ...query },
      })
    },
  },
}
</script>

<style scoped lang="less">
.page {
  width: 94%;
  margin: 16px auto;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.header {
  padding: 16px 20px;
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    color: #fff;
    font-size: 20px; /* 增加标题字体大小 */
    font-weight: 600; /* 加粗标题 */
  }
}

.warning-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px; /* 增加间隔 */
  padding: 20px;
}

.number-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 16px;
  border-radius: 10px; /* 圆角效果 */
  //background: rgba(240, 240, 240, 0.8); /* 背景颜色 */
  transition: transform 0.3s, box-shadow 0.3s; /* 添加过渡效果 */

  &:hover {
    transform: translateY(-5px); /* 悬停时抬高效果 */
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15); /* 悬停时阴影效果 */
  }
}

.icon-container {
  margin-bottom: 8px; /* 图标下方间距 */
}

.item-name {
  font-size: 15px; /* 适当调整字体大小 */
  font-family: PingFangSC, PingFang SC, serif;
  text-align: center;
}
</style>
